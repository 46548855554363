import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'

class Professional extends Component {
    state = {
        show1: false,
        show2: false,
        show3: false
    }

    Alert = (a) =>  {
        if (a === 'a1') this.setState({ show1: true })
        else if(a === 'a2') this.setState({ show2: true })
        else this.setState({ show3: true })
    }
    closeAlert = (a) => {
        if (a === 'a1') this.setState({ show1: false })
        else if(a === 'a2') this.setState({ show2: false })
        else this.setState({ show3: false })
    }
    render() {
        return (
            <Container className="boxPro">
                <Row>
                    <Col lg="12">
                        <h1 className="topicTop text-center">มาตรฐานวิชาชีพ</h1>
                        <h5 className="topic_sec">ข้อบังคับคุรุสภา ว่าด้วยมาตรฐานวิชาชีพ</h5>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" style={{ paddingTop: "50px" }}>
                        <p>
                            มาตรฐานวิชาชีพ คือ ข้อกำหนดเกี่ยวกับคุณลักษณะ และคุณภาพที่พึงประสงค์ในการประกอบวิชาชีพทางการศึกษา 
                            ซึ่งผู้ประกอบวิชาชีพทางการศึกษาต้องประพฤติปฏิบัติตาม ประกอบด้วย
                        </p>
                        <div className="boxText">
                            <h5>1. มาตรฐานความรู้และประสบการณ์วิชาชีพ</h5>
                            <p>
                                หมายถึง ข้อกำหนดเกี่ยวกับความรู้และประสบการณ์ในการจัดการเรียนรู้ หรือการจัดการศึกษา 
                                ซึ่งผู้ประกอบวิชาชีพทางการศึกษา รวมทั้งผู้ต้องการประกอบวิชาชีพทางการศึกษา 
                                ต้องมีเพียงพอที่สามารถนำไปใช้ในการประกอบวิชาชีพได้
                            </p>
                            <p onClick={() => this.Alert('a1')} style={{color:"#b7996c", fontSize:"14px", fontWeight:300, cursor: "pointer"}}> รายละเอียดทั้งหมด</p>
                            {
                                this.state.show1 ? 
                                <div>
                                    <Alert variant="primary" onClose={() => this.closeAlert('a1')} show={this.state.show1} dismissible>
                                        <p style={{ fontWeight: 300,fontSize:"14px" }}>ผู้ประกอบวิชาชีพครู ต้องมีคุณวุฒิไม่ต่ำกว่าปริญญาตรีทางการศึกษาหรือเทียบเท่า หรือมีคุณวุฒิอื่นที่คุรุสภารับรอง โดยมีมาตรฐานความรู้และประสบการณ์วิชาชีพ ดังต่อไปนี้</p>
                                        <ul style={{fontSize:"14px"}}>
                                            <li>(ก) มาตรฐานความรู้
                                                <ul>
                                                    <li>การเปลี่ยนแปลงบริบทของโลก สังคม และแนวคิดของปรัชญาเศรษฐกิจพอเพียง</li>
                                                    <li>จิตวิทยาพัฒนาการ จิตวิทยาการศึกษา และจิตวิทยาให้คำปรึกษาในการวิเคราะห์และพัฒนาผู้เรียน</li>
                                                    <li>เนื้อหาวิชาที่สอน หลักสูตร ศาสตร์การสอน และเทคโนโลยีดิจิทัลในการจัดการเรียนรู้</li>
                                                    <li>การวัด ประเมินผลการเรียนรู้ และการวิจัยเพื่อแก้ไขปัญหาและพัฒนาผู้เรียน</li>
                                                    <li>การใช้ภาษาไทย ภาษาอังกฤษเพื่อการสื่อสาร และการใช้เทคโนโลยีดิจิทัลเพื่อการศึกษา</li>
                                                    <li>การออกแบบและการดำเนินการเกี่ยวกับงานประกันคุณภาพการศึกษา</li>
                                                </ul>
                                            </li>
                                            <li>(ข) มาตรฐานประสบการณ์วิชาชีพ
                                                <ul>
                                                    <li>การฝึกปฏิบัติวิชาชีพระหว่างเรียน</li>
                                                    <li>การปฏิบัติการสอนในสถานศึกษาในสาขาวิชาเฉพาะ</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </Alert>
                                </div>
                                :null
                            }
                        </div> 
                        <br />

                        <div className="boxText">
                            <h5>2. มาตรฐานการปฏิบัติงาน</h5>
                            <p>
                                หมายถึง ข้อกำหนดเกี่ยวกับคุณลักษณะ หรือการแสดงพฤติกรรมการปฏิบัติงานและการพัฒนางาน 
                                ซึ่งผู้ประกอบวิชาชีพทางการศึกษา รวมทั้งผู้ต้องการประกอบวิชาชีพทางการศึกษาต้องปฏิบัติตาม 
                                เพื่อให้เกิดผลตามวัตถุประสงค์ และเป้าหมายการเรียนรู้หรือการจัดการศึกษา รวมทั้งต้องฝึกฝนพัฒนาตนเองให้มีทักษะ 
                                หรือความชำนาญสูงขึ้นอย่างต่อเนื่อง
                            </p>
                            <p onClick={() => this.Alert('a2')} style={{color:"#b7996c", fontSize:"14px", fontWeight:300, cursor: "pointer"}}> รายละเอียดทั้งหมด</p>
                            {
                                this.state.show2 ? 
                                <div>
                                    <Alert variant="primary" onClose={() => this.closeAlert('a2')} show={this.state.show2} dismissible>
                                        <p style={{ fontWeight: 300,fontSize:"14px" }}>ผู้ประกอบวิชาชีพครูต้องมีมาตรฐานการปฏิบัติงาน ดังต่อไปนี้</p>
                                        <ul style={{fontSize:"14px"}}>
                                            <li>(ก) การปฏิบัติหน้าที่ครู
                                                <ul>
                                                    <li>มุ่งมั่นพัฒนาผู้เรียน ด้วยจิตวิญญาณความเป็นครู</li>
                                                    <li>ประพฤติตนเป็นแบบอย่างที่ดี มีคุณธรรม จริยธรรม และมีความเป็นพลเมืองที่เข้มแข็ง</li>
                                                    <li>ส่งเสริมการเรียนรู้ เอาใจใส่ และยอมรับความแตกต่างของผู้เรียนแต่ละบุคคล</li>
                                                    <li>สร้างแรงบันดาลใจผู้เรียนให้เป็นผู้ใฝ่เรียนรู้ และผู้สร้างนวัตกรรม</li>
                                                    <li>พัฒนาตนเองให้มีความรอบรู้ ทันสมัย และทันต่อการเปลี่ยนแปลง</li>
                                                </ul>
                                            </li>
                                            <li>(ข) การจัดการเรียนรู้
                                                <ul>
                                                    <li>พัฒนาหลักสูตรสถานศึกษา การจัดการเรียนรู้ สื่อ การวัดและประเมินผลการเรียนรู้</li>
                                                    <li>บูรณาการความรู้และศาสตร์การสอนในการวางแผนและจัดการเรียนรู้ที่สามารถพัฒนาผู้เรียนให้มีปัญญารู้คิด และมีความเป็นนวัตกร</li>
                                                    <li>ดูแล ช่วยเหลือ และพัฒนาผู้เรียนเป็นรายบุคคลตามศักยภาพ สามารถรายงานผลการพัฒนาคุณภาพผู้เรียนได้อย่างเป็นระบบ</li>
                                                    <li>จัดกิจกรรมและสร้างบรรยากาศการเรียนรู้ให้ผู้เรียนมีความสุขในการเรียนโดยตระหนักถึงสุขภาวะของผู้เรียน</li>
                                                    <li>วิจัย สร้างนวัตกรรม และประยุกต์ใช้เทคโนโลยีดิจิทัลให้เกิดประโยชน์ต่อการเรียนรู้ของผู้เรียน</li>
                                                    <li>ปฏิบัติงานร่วมกับผู้อื่นอย่างสร้างสรรค์และมีส่วนร่วมในกิจกรรมการพัฒนาวิชาชีพ</li>
                                                </ul>
                                            </li>
                                            <li>(ค) ความสัมพันธ์กับผู้ปกครองและชุมชน
                                                <ul>
                                                    <li>ร่วมมือกับผู้ปกครองในการพัฒนาและแก้ปัญหาผู้เรียนให้มีคุณลักษณะที่พึงประสงค์</li>
                                                    <li>สร้างเครือข่ายความร่วมมือกับผู้ปกครองและชุมชน เพื่อสนับสนุนการเรียนรู้ที่มีคุณภาพของผู้เรียน</li>
                                                    <li>ศึกษา เข้าถึงบริบทของชุมชน และสามารถอยู่ร่วมกันบนพื้นฐานความแตกต่างทางวัฒนธรรม</li>
                                                    <li>ส่งเสริม อนุรักษ์วัฒนธรรม และภูมิปัญญาท้องถิ่น</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </Alert>
                                </div>
                                :null
                            }
                        </div>
                        <br />

                        <div className="boxText">
                            <h5>3. มาตรฐานการปฏิบัติตน</h5>
                            <p>
                                หมายถึง จรรยาบรรณของวิชาชีพที่กำหนดขึ้นเป็นแบบแผนในการประพฤติปฏิบัติตน 
                                ซึ่งผู้ประกอบวิชาชีพทางการศึกษา รวมทั้งผู้ต้องการประกอบวิชาชีพทางการศึกษาต้องยึดถือปฏิบัติตาม 
                                เพื่อรักษาและส่งเสริมเกียรติคุณชื่อเสียง และฐานะของผู้ประกอบวิชาชีพทางการศึกษาให้เป็นที่เชื่อถือศรัทธาแก่ผู้รับบริการ
                                และสังคม อันจะนำมาซึ่งเกียรติ และศักดิ์ศรีแห่งวิชาชีพ
                            </p>
                            <p onClick={() => this.Alert('a3')} style={{color:"#b7996c", fontSize:"14px", fontWeight:300, cursor: "pointer"}}> รายละเอียดทั้งหมด</p>
                            {
                                this.state.show3 ? 
                                <div>
                                    <Alert variant="primary" onClose={() => this.closeAlert('a3')} show={this.state.show3} dismissible>
                                        <p style={{ fontWeight: 300,fontSize:"14px" }}>ผู้ประกอบวิชาชีพครูต้องมีมาตรฐานการปฏิบัติตน (จรรยาบรรณของวิชาชีพ) ดังต่อไปนี้</p>
                                        <ul style={{fontSize:"14px"}}>
                                            <li>จรรยาบรรณต่อตนเอง
                                                <ul>
                                                    <li>ผู้ประกอบวิชาชีพทางการศึกษา ต้องมีวินัยในตนเอง พัฒนาตนเองด้านวิชาชีพ บุคลิกภาพ และวิสัยทัศน์ ให้ทันต่อการพัฒนาทางวิทยาการ เศรษฐกิจ สังคม และการเมืองอยู่เสมอ</li>
                                                </ul>
                                            </li>
                                            <li>จรรยาบรรณต่อวิชาชีพ
                                                <ul>
                                                    <li>ผู้ประกอบวิชาชีพทางการศึกษา ต้องรัก ศรัทธา ซื่อสัตย์สุจริต รับผิดชอบต่อวิชาชีพ และเป็นสมาชิกที่ดีขององค์กรวิชาชีพ</li>
                                                </ul>
                                            </li>
                                            <li>จรรยาบรรณต่อผู้รับบริการ
                                                <ul>
                                                    <li>ผู้ประกอบวิชาชีพทางการศึกษา ต้องรัก เมตตา เอาใจใส่ ช่วยเหลือ ส่งเสริม ให้กำลังใจแก่ศิษย์และผู้รับบริการ ตามบทบาทหน้าที่โดยเสมอหน้า</li>
                                                    <li>ผู้ประกอบวิชาชีพทางการศึกษา ต้องส่งเสริมให้เกิดการเรียนรู้ ทักษะ และนิสัยที่ถูกต้องดีงามแก่ศิษย์และผู้รับบริการ ตามบทบาทหน้าที่อย่างเต็มความสามารถด้วยความบริสุทธิ์ใจ</li>
                                                    <li>ผู้ประกอบวิชาชีพทางการศึกษา ต้องประพฤติปฏิบัติตนเป็นแบบอย่างที่ดี ทั้งทางกาย วาจาและจิตใจ</li>
                                                    <li>ผู้ประกอบวิชาชีพทางการศึกษา ต้องไม่กระทำตนเป็นปฏิปักษ์ต่อความเจริญ ทางกาย สติปัญญา จิตใจ อารมณ์และสังคมของศิษย์และผู้รับบริการ</li>
                                                    <li>ผู้ประกอบวิชาชีพทางการศึกษา ต้องให้บริการด้วยความจริงใจและเสมอภาค โดยไม่เรียกรับหรือยอมรับผลประโยชน์จากการใช้ ตำแหน่งหน้าที่โดยมิชอบ</li>
                                                </ul>
                                            </li>
                                            <li>จรรยาบรรณต่อผู้ร่วมประกอบวิชาชีพ
                                                <ul>
                                                    <li>ผู้ประกอบวิชาชีพทางการศึกษาพึงช่วยเหลือเกื้อกูลซึ่งกันและกันอย่างสร้างสรรค์ โดยยึดมั่นในระบบคุณธรรม สร้างความสามัคคีในหมู่คณะ</li>
                                                </ul>
                                            </li>
                                            <li>จรรยาบรรณต่อสังคม
                                                <ul>
                                                    <li>ผู้ประกอบวิชาชีพทางการศึกษา พึงประพฤติปฏิบัติตน เป็นผู้นำในการอนุรักษ์ และพัฒนาเศรษฐกิจ สังคม ศาสนา ศิลปวัฒนธรรม ภูมิปัญญา สิ่งแวดล้อมรักษาผลประโยชน์ ของส่วนรวมและยึดมั่นในการปกครองระบอบประชาธิปไตยอันมีพระมหากษัตริย์ทรงเป็นประมุข</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </Alert>
                                </div>
                                :null
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
export default Professional;
